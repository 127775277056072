import React, { useMemo, useCallback } from 'react';
import LazyLoad from 'react-lazyload';
import TitlebarInner from './TitlebarInner';
import { Link, useParams } from 'react-router-dom';
import { blog } from "../data/blogs";
import MetaData from './MetaData';
import { metaData } from '../metaData/meta';

const BlogPage = () => {
    const { category } = useParams();

    // Memoized function to filter blogs by date
    const filterBlogsByDate = useCallback((blogs) => {
        const currentDate = new Date();
        return blogs.filter(blog => {
            const blogDate = new Date(blog.created_at);
            return blogDate <= currentDate;
        });
    }, []);

    // Memoized list of blogs filtered by date
    const blogs = useMemo(() => filterBlogsByDate(blog), [blog, filterBlogsByDate]);

    // Memoized function to convert slug to original name
    const convertSlugToOriginalName = useCallback((slug) => {
        const words = slug.split('-');
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }, []);

    // Memoized filtered blogs based on category
    const filteredBlogs = useMemo(() => category ?
        blogs.filter(blog => blog.categoryName === convertSlugToOriginalName(category)) :
        blogs, [blogs, category, convertSlugToOriginalName]);

    // Memoized function to truncate title
    const truncateTitle = useCallback((title) => {
        return title.length > 70 ? title.substring(0, 70) + "..." : title;
    }, []);

    return (
        <>
            <MetaData metaData={metaData["blogPage"]} />
            <TitlebarInner PageName="Blog" PageDec="We harness the tools of traditional and digital." />
            <main className="content bg-top-center bg-cover blog-background-image" id="lqd-site-content">
                <div id="lqd-contents-wrap">
                    <section className="lqd-section blog py-80" id="blog">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12">
                                    <ul className="axil-tab-button nav nav-tabs mt--20 row -mx-15" id="axilTab" role="tablist">
                                        <li className={`nav-item p-2 ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element3 ml-2`} style={{ marginRight: '10px', backgroundColor: '#fff7f4' }} role="presentation">
                                            <Link to={`/blog`} style={{ color: '#1a3760d4' }} className={`nav-link ld-fh-element relative m-0 py-10 px-20 ${category === undefined ? 'btn-primary' : ''}`}>
                                                All
                                            </Link>
                                        </li>
                                        {
                                            Array.from(new Set(blogs.map(data => data.categoryName))).map((categoryName, index) => {
                                                const bgClass = categoryName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-').replace(/:/g, '') === category ? 'btn-primary' : '';
                                                return (
                                                    <li key={index} className={`nav-item p-2 ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element3 ml-2`} style={{ marginRight: '10px', backgroundColor: '#fff7f4' }} role="presentation">
                                                        <Link to={`/blog/category/${categoryName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-').replace(/:/g, '')}`} style={{ color: '#1a3760d4' }} className={`nav-link ld-fh-element relative m-0 py-10 px-20 ${bgClass}`}>
                                                            {categoryName}
                                                        </Link>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="col col-12">
                                    <div className="row -mx-15" id='blog-section'>
                                        {
                                            filteredBlogs.sort((a, b) => {
                                                return (new Date(b.created_at) - new Date(a.created_at))
                                            }).map((data, ind) => {
                                                return (
                                                    <div key={ind} className="col col-12 col-sm-6 col-lg-4 mb-30 px-15 py-0">
                                                        <Link to={`/${data.title.toLowerCase().replace(/\//g, '-').replace(/ /g, '-').replace(/:/g, '').replace(/\?/g, '')}`}>
                                                            <article className="lqd-lp relative lqd-lp-style-6 lqd-lp-hover-img-zoom lqd-lp-animate-onhover rounded-4 overflow-hidden text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single tag-beauty tag-travel">
                                                                <div className="lqd-lp-img overflow-hidden">
                                                                    <LazyLoad height={200} offset={100}>
                                                                        <figure>
                                                                            <img
                                                                                srcSet={`../../assets/images/${data.src}?w=480 480w, ../../assets/images/${data.src}?w=720 720w, ../../assets/images/${data.src}?w=1200 1200w`}
                                                                                sizes="(max-width: 600px) 480px, (max-width: 1200px) 720px, 1200px"
                                                                                src={`../../assets/images/${data.src}`}
                                                                                alt="modernagencywp"
                                                                            />
                                                                        </figure>
                                                                    </LazyLoad>
                                                                </div>
                                                                <header className="lqd-lp-header p-1rem" style={{ minHeight: "106px" }}>
                                                                    <div className="lqd-lp-meta uppercase font-bold relative z-3">
                                                                        <span className="screen-reader-text">Tags</span>
                                                                        <ul className="lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid reset-ul inline-ul uppercase tracking-0/1em">
                                                                            <li>
                                                                                <a className="rounded-full" href="#" rel="category" style={{ padding: "0px !important" }}>{data.categoryName}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="entry-title lqd-lp-title mb-0 h5 pt-5">
                                                                        <a className="" rel="bookmark"> {truncateTitle(data.title)}</a>
                                                                    </h2>
                                                                </header>
                                                            </article>
                                                        </Link>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}


export default BlogPage;
